<template>
    <div>
        <hr class="my-4">

        <div class="col-12">
            <h3 class="mb-0">RRR Payment Details</h3>
        </div>

        <hr class="my-4">

        <div class="table-responsive">

            <table class="table table-bordered">
                <thead>
                    <th width="10%">
                        S/N
                    </th>
                    <th width="50%">
                        RRR
                    </th>
                    <th width="40%">
                        Amount
                    </th>
                </thead>

                <tbody
                    v-for="(record, index) in paymentDetails"
                    :key="index"
                >
                    <td>
                        {{ index+1 }}
                    </td>
                    <td>
                        {{ record.RRR }}
                    </td>
                    <td>
                    {{ record.amount | formatPrice }}
                    </td>
                </tbody>

            </table>

        </div>

        <hr class="my-4">

        <div class="col-lg-12">
            <base-button
                type="success" size="lg"
                v-if="showAddPaymentButton"
                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                @click="updatePayment()"
            >
                <i class="fa fa-credit-card"></i>
                {{ isloading ? 'Please Wait' : 'Add Payment' }}
            </base-button>

            <base-button
                type="danger" size="lg"
                class="right"
                @click="$emit('removeAddPayment')"
            >
                <i class="fa fa-times"></i>
                Cancel
            </base-button>
        </div>



        <!-- <hr class="my-4">
        <h2
            class="mb-0 text-uppercase text-center"
        >
            Add Payment to
            <small class="text-danger">
                {{ fullName(user) }}
            </small>
            's Account
        </h2>
        <hr class="my-4">


        <h2 class="text-center">
            Payment Details
        </h2>

        <hr class="my-4">

        <h3 class="text-center">
            Application Type: &nbsp;
            <em class="text-capitalize font-weight-light">
                {{ applicationType.name }}
            </em>
        </h3>
        <h3 class="text-center text-danger">
            Amount Required: &nbsp;
            <em class="text-capitalize font-weight-light">
                {{ applicationType.has_payment.amount | formatPrice }}
            </em>
        </h3>
        <h3 class="text-center">
            Amount Paid: &nbsp;
            <em class="text-capitalize font-weight-light">
                {{ paymentDetails.amount | formatPrice }}
            </em>
        </h3>
        <h3 class="text-center">
            RRR Number: &nbsp;
            <em class="text-capitalize font-weight-light">
                {{ paymentDetails.RRR }}
            </em>
        </h3>
        <h3 class="text-center">
            Payment Date: &nbsp;
            <em class="font-weight-light">
                {{ paymentDetails.paymentDate | getDateTimeFormat }}
            </em>
        </h3>

        <hr class="my-4">

        <div class="text-center">
            <base-button
                type="success" size="lg"
                @click="updatePayment()"
            >
                <i class="fa fa-credit-card"></i>
                {{ isloading ? 'Please Wait' : 'Add Payment' }}
            </base-button>
            <base-button
                type="danger" size="lg"
                @click="$emit('removeAddPayment')"
            >
                <i class="fa fa-times"></i>
                Cancel
            </base-button>
        </div>             -->


    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AddPaymentDetails',
        props: ["paymentDetails", "applicationType", "privileges", "user", "record", "removeAddPayment"],
        data() {
            return {
                rrr: null,
                isloading: false,
            }
        },
        filters: {
            getDateTimeFormat(value) {
                if(value) {
                    return moment(value).format('Do MMM, YYYY [at] h:mm:ss a');
                } else {
                    return 'N/A';
                }
            },
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            showAddPaymentButton() {
                let total = 0;
                for(let payment of this.paymentDetails) {
                    total += payment.amount;
                }
                return total >= this.applicationType.has_payment.amount;
            }
        },
        methods: {
            ...mapActions(['updateApplicationTransaction']),
            getYear(value) {
                return moment(value).format('YYYY');
            },
            updatePayment() {// update transaction
                this.isloading = true;
                let data = {};
                data.payments = this.paymentDetails;
                data.trans_no = this.genRandomString(12);// generate trans no
                data.user_data_id = this.user.id; // add user id
                data.application_type_id = this.record.application_type_id; // add application type id
                data.license_year = this.record.license_year ? this.record.license_year : null;// add license year
                if(this.showAddPaymentButton) {// add payment and update trans
                    this.makePaymentApiCall(data);
                } else {
                    this.isloading = false;
                    let message = 'Amount on the RRR is less than the required amount';
                    swal.fire('Invalid Amount!', message, 'warning');
                }
            },
            makePaymentApiCall(data) {// update transaction Api call
                this.preloader();// show loading
                this.updateApplicationTransaction(data).then((res) => {// update transaction
                    if(res.status) {
                        swal.fire('Payment Added!', res.msg, 'success');
                    } else {
                        swal.fire('Payment Failed!', res.msg, 'warning');
                    }
                    return this.removeAddPayment();// close payment detail page
                }).
                catch(() => {
                    let message = 'An error while adding payment, kindly reload and try again';
                    swal.fire('Server Failure!', message, 'warning');
                });
            },
            genRandomString(length) {
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>