
<template>
    <div>

        <div class="pl-lg-4">
            <template>
                <tabs fill class="flex-column flex-md-row">
                    <card shadow>
                        <tab-pane title="Basic Profile">
                            <span slot="title">
                                <i class="ni ni-circle-08"></i>
                                Basic Profile
                            </span>
                            <!-- import User Basic Profile Details template here -->
                            <BasicProfile
                                v-bind:record="record"
                                v-bind:privileges="privileges"
                                :isHodOrSuper="isHodOrSuper" 
                            />
                        </tab-pane>

                        <tab-pane title="Applications">
                            <span slot="title">
                                <i class="ni ni-archive-2"></i>
                                Applications
                            </span>
                            <!-- import User Applications template here -->
                            <Applications
                                v-bind:record="record"
                                v-bind:privileges="privileges"
                                :isHodOrSuper="isHodOrSuper"
                            />
                        </tab-pane>

                        <tab-pane title="Additional Qualifications">
                            <span slot="title">
                                <i class="ni ni-archive-2"></i>
                                Additional Qualifications
                            </span>
                            <!-- import User Additional Qualifications template here -->
                            <AddQualifications
                                v-bind:record="record"
                                v-bind:privileges="privileges"
                            />
                        </tab-pane>

                        <tab-pane title="Medical School">
                            <span slot="title">
                                <i class="ni ni-books"></i>
                                Medical School
                            </span>
                            <!-- import User Medical School template here -->
                            <MedicalSchool
                                v-bind:record="record"
                                v-bind:privileges="privileges"
                            />
                        </tab-pane>
                    </card>
                </tabs>
            </template>
        </div>

        <hr class="my-4" />

        <div class="pl-lg-4">
            <div class="col-lg-12">
                <base-button
                    :type="getColor(record.profile_lock)" size="lg"
                    @click="enableEdit()"
                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                >
                    <i :class="'fa fa-'+getIcon(record.profile_lock )"></i>
                    {{record && (record.profile_lock == 'yes') ?
                        'Enable' : 'Disable'}} Edit
                </base-button>

                <base-button
                    type="danger" size="lg"
                    @click="deleteAccount()"
                    :disabled="! isHodOrSuper"
                >
                    <i class="fa fa-trash"></i>
                    Delete Account
                </base-button>

                <base-button
                    type="info" size="lg"
                    @click="resetPassword()"
                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                >
                    <i class="fa fa-history"></i>
                    Reset Password
                </base-button>

                <base-button
                    type="danger" size="lg"
                    class="right"
                    @click="$emit('hideProfile')"
                    :disabled="(privileges && privileges.view == 'no') || ! privileges"
                >
                    <i class="fa fa-times"></i>
                    Close Profile
                </base-button>
            </div>
        </div>

        <!-- the modal for password reset  -->
        <div class="col-md-4" :show="showPasswordModal">
            <modal :show="showPasswordModal"
                    gradient="danger"
                    modal-classes="modal-danger modal-dialog-centered">
                <h4
                    slot="header" class="modal-title"
                    id="modal-title-notification"
                >
                    New Password
                </h4>

                <div class="py-3 text-center">
                    <i class="ni ni-bell-55 ni-3x"></i>
                    <h2 class="text-white">
                        Password: {{ password }}
                    </h2>
                </div>

                <template slot="footer">
                    <base-button type="link"
                                text-color="white"
                                class="ml-auto"
                                @click="showPasswordModal = false">
                        Close
                    </base-button>
                </template>
            </modal>
        </div>

    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import BasicProfile from './BasicProfile';
  import AddQualifications from './AddQualifications';
  import Applications from './Applications';
  import MedicalSchool from './MedicalSchool';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'Profile',
    props: ['record','privileges', 'hideProfile'],
    components: {
      BasicProfile,
      AddQualifications,
      Applications,
      MedicalSchool
    },
    data() {
      return {
        key: null,
        isLoading: false,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        showProfile: false,
        password: null,
        showPasswordModal: false
      }
    },
    computed: {
        ...mapGetters(['user']),
        isHodOrSuper() {// check if user is HOD or Super Admin 
            let isHod = this.user && this.user.is_hod == 'yes';// if user is HOD
            let isSuper = this.user && this.user.unit && this.user.unit.department
                        && this.user.unit.department.code == 'super-admin';
            return isHod || isSuper;
        }
    },
    methods: {
        ...mapActions(['enableUserEdit', 'deleteUserAcount', 'resetDoctorPassword']),
        enableEdit() {// enable doctor to edit profile
            swal.fire({
                title: 'Are you sure?',
                text: "You won't to continue with this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeEditApiCall();// make api call
                }
            });
        },
        makeEditApiCall() {// enable edit api call
            this.preloader(); // show loading
            this.enableUserEdit(this.record.id).then((res) => {
                if(res.status) {
                    this.record.profile_lock = res.profile_lock;
                    swal.fire('Profile '+res.type, res.msg, 'success');
                } else {
                    let msg = "An error occurred while updating privilege, kindly try again...";
                    swal.fire('Error Occurred!', msg, 'error');
                }
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        resetPassword() {
            swal.fire({
                title: 'Are you sure?',
                text: "You won't to continue with this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeResetPasswordApiCall();// make api call
                }
            });
        },
        makeResetPasswordApiCall() {// enable edit api call
            this.preloader(); // show loading
            this.password = null;
            this.showPasswordModal = false;
            let data = { id: this.record.id, admin_id: this.user.id };
            this.resetDoctorPassword(data).then((res) => {
                if(res.status) {
                    swal.close();
                    this.password = res.password;
                    this.showPasswordModal = true;
                } else {
                    let msg = "An error occurred while resetting password, kindly try again...";
                    swal.fire('Error Occurred!', msg, 'error');
                }
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        deleteAccount() {
            if(this.isHodOrSuper) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Delete It!'
                }).then((result) => {
                    if (result.value) {
                        this.makeDeleteApiCall();// make api call
                    }
                });
            } else {
                swal.fire('Permission Denied', 'You don\'t the necessary permission', 'warning');
            }  
        },
        makeDeleteApiCall() {// delete api call
            this.preloader(); // show loading
            this.deleteUserAcount(this.record.id).then((res) => {
                if(res.status) {
                    this.hideProfile(); // close profile
                    swal.fire('Account Deleted!', res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        getColor(value) {
            return value == 'yes' ? 'primary' : 'danger';
        },
        getIcon(value) {
            return value == 'no' ? 'ban' : 'check';
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    }
  };
</script>
<style scoped>
.right {
    float: right;
}
</style>
